.chapter-border-0 {
    border-color: rgb(204 162 102);
}

.chapter-bg-0 {
    background-color: rgb(245 245 245);
}

.chapter-border-1 {
    border-color: rgb(204 162 102);
}

.chapter-bg-1 {
    background-color: rgb(242 230 206);
}

.chapter-border-2 {
    border-color: rgb(46 153 46);
}

.chapter-bg-2 {
    background-color: rgb(207 229 207);
}

.chapter-border-3 {
    border-color: rgb(143 143 143);
}

.chapter-bg-3 {
    background-color: rgb(24 24 25);
}

.chapter-text-0,
.chapter-text-1,
.chapter-text-2 {
    color: rgb(31 41 55);
}

.chapter-text-3 {
    color: rgb(156 163 175);
}